import React, { useState, useEffect } from 'react'
import PromptBar from '../../components/PromptBar/PromptBar'
import { generateAIResponse } from '../../api/prompt';
import { toast } from 'react-toastify'
import Messages from './Messages/Messages';
import { getMessages, saveMessage } from '../../api/messages';
import { useParams } from 'react-router';
import { useConversations } from '../../contexts/ConversationsContext';

function Conversation() {

    const { uuid } = useParams();
    const [query, setQuery] = useState('');
    const [messages, setMessages] = useState([]);
    const [loadingResponse, setLoadingResponse] = useState(false);
    const { refreshConversations } = useConversations();

    const [models, setModels] = useState([
        {
            name: "gpt",
            label: "ChatGPT",
            selected: true
        },
        {
            name: "gemini",
            label: "Gemini",
            selected: true
        },
        {
            name: "perplexity",
            label: "Perplexity",
            selected: true
        },
        {
            name: "lama",
            label: "Lama",
            selected: true
        },
        {
            name: "mistral",
            label: "Mistral",
            selected: true
        }
    ])

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const fetchedMessages = await getMessages(uuid);
                setMessages(fetchedMessages);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchMessages();
    }, [uuid]);

    useEffect(() => {
        if (messages.length === 1) {
            refreshConversations();
        }
    }, [messages]);

    const toggleSelection = (index) => {
        setModels((prevModels) =>
            prevModels.map((model, i) =>
                i === index ? { ...model, selected: !model.selected } : model
            )
        );
    };

    const handleSubmitQuery = async (e) => {
        e.preventDefault();
        if (query.trim()) {

            const message = {
                content: query.trim(),
                role: "user"
            }

            try {
                setLoadingResponse(true);
                const newMessage = await saveMessage(uuid, message);
                setMessages(prevMessages => [...prevMessages, newMessage]);
                setQuery("");

                const prompt = {
                    uuid: uuid,
                    prompt: query
                }

                const replies = await Promise.all(
                    models
                        .filter(model => model.selected)
                        .map(model => generateAIResponse(model.name, prompt))
                );

                setMessages(prevMessages => [...prevMessages, ...replies]);

            } catch (error) {
                toast.error(error);
            }
            finally {
                setLoadingResponse(false);
            }
        }
    };

    return (
        <>

            <Messages
                messages={messages}
                loadingResponse={loadingResponse}
            />

            <PromptBar
                models={models} setModels={setModels}
                query={query} setQuery={setQuery}
                handleSubmitQuery={handleSubmitQuery}
                toggleSelection={toggleSelection}
            />

        </>
    )
}

export default Conversation

import React, { useState } from 'react';
import { ReactComponent as GPTLogo } from '../../../../common/assets/img/AI_logos/gpt.svg';
import { ReactComponent as GeminiLogo } from '../../../../common/assets/img/AI_logos/gemini.svg';
import { ReactComponent as PerplexityLogo } from '../../../../common/assets/img/AI_logos/perplexity.svg';
import { ReactComponent as LamaLogo } from '../../../../common/assets/img/AI_logos/lama.svg';
import { ReactComponent as MistralLogo } from '../../../../common/assets/img/AI_logos/mistral.svg';
import styles from './Messages.module.css';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

const Message = ({ message }) => {

    const logoMapping = {
        gpt: GPTLogo,
        gemini: GeminiLogo,
        perplexity: PerplexityLogo,
        lama: LamaLogo,
        mistral: MistralLogo
    };

    const Logo = logoMapping[message.role.toLowerCase()];
    const [expanded, setExpanded] = useState(false);

    const handleToggleExpand = () => setExpanded(!expanded);

    return (
        <div className={`pt-4 ${message.role === 'user' ? 'd-flex justify-content-end' : 'col-md-12 d-flex flex-column align-items-center'}`} key={message.id}>
            <div>
                {message.role !== 'user' && Logo && (
                    <h5 className='d-flex align-items-center'>
                        <Logo className="me-2" /> {message.role.charAt(0).toUpperCase() + message.role.slice(1)}
                    </h5>
                )}
                <div className={`${styles.message} ${message.role === 'user' ? styles.userMessage : styles.assistantMessage}`}>
                    <span className={expanded ? styles.expanded : styles.collapsed}>{message.content}</span>
                    {message.content.split('\n').length > 6 && (
                        <div className={styles.showMoreWrapper}>
                            <span onClick={handleToggleExpand} className='pointer'>
                                {expanded ? <div>Show less <ChevronUp /></div> : <div>Show more <ChevronDown /></div>}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Message;

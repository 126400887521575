import React, { useState } from 'react'
import { SendFill } from 'react-bootstrap-icons'
import styles from './PromptBar.module.css'

function PromptBar({ models, setModels, toggleSelection, query, setQuery, handleSubmitQuery }) {

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.shiftKey) {
                return;
            } else {
                e.preventDefault();
                handleSubmitQuery(e);
            }
        }
    };

    return (
        <div id={styles.promptBarWrapper}>
            <form className='d-flex pt-3' onSubmit={handleSubmitQuery}>
                <textarea
                    name="query"
                    id={styles.PromptBar}
                    rows={1}
                    className='form-control border-0'
                    style={{ resize: 'none' }}
                    placeholder='Ask us anything...'
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                ></textarea>
                <div className='ps-2'>
                    <button id={styles.PromptSubmitBtn} className='btn btn-primary' type="submit">
                        <SendFill />
                    </button>
                </div>
            </form>

            <div className='d-flex px-2 pt-4'>
                {models.map((model, index) => (
                    <div key={model.name} className='d-flex align-items-center pe-4'>
                        <input
                            type="checkbox"
                            className='form-check-input'
                            checked={model.selected}
                            onChange={() => toggleSelection(index)}
                        />
                        <span className='ps-2 txt-lighter'>{model.label}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PromptBar;

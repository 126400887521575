import React from 'react'
import { BarChartFill, CodeSlash, LightbulbFill, MortarboardFill } from 'react-bootstrap-icons'
import CreateNewChat from '../../components/CreateNewChat'
import TypingTextAnimation from '../../../common/components/TypingTextAnimation/TypingTextAnimation'

function Playground() {

  return (
    <div className='container d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>

      <TypingTextAnimation text="Experience the Power of AI, Combined" delay={35} infinite={false} />



      <CreateNewChat />

    </div>
  )
}

export default Playground

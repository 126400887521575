import Conversation from "./pages/Conversation/Conversation";
import Playground from "./pages/Playground/Playground";

const playgroundRoutes = [
  {
    path: '/',
    element: <Playground />,
  },
  {
    path: '/:uuid',
    element: <Conversation />,
  },
];

export default playgroundRoutes;
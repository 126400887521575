import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../common/layouts/Header/Header'
import Sidebar from '../components/Sidebar';
import { getUserInfo } from '../../account/api/user';

function PlaygroundLayout() {

    const [userInfo, setUserInfo] = useState([]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchUserInfo();
    }, []);

    return (
        <div className="page-content-wrapper">
            <Sidebar />
            <div className="main-content-wrapper">
                <div className="main-container">
                    <Header userInfo={userInfo} />
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default PlaygroundLayout
import { apiUrl } from '../../common/api/config'

export const generateAIResponse = async (model, prompt) => {

    try {

        const token = localStorage.getItem('accessToken');

        const response = await fetch(apiUrl + '/prompt_manager/'+model+'/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(prompt)
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}
import React from 'react';
import { BarChartFill, CodeSlash, LightbulbFill, MortarboardFill } from 'react-bootstrap-icons'

const ConversationsWelcomeScreen = () => {
    return (
        <div className='container d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>

            <h1 className='ps-2 bold mb-4 mt-3'>
                Chat with Multiple AI Models
            </h1>
            <div className='d-flex flex-wrap align-items-center mb-5'>
                <div className='pe-2 pt-2'>
                    <button className='btn btn-outline-secondary medium px-3 py-2 txt-lighter rounded-lg d-flex align-items-center'>Brainstorm <LightbulbFill className='ms-1 text-warning' /></button>
                </div>
                <div className='pe-2 pt-2'>
                    <button className='btn btn-outline-secondary medium px-3 py-2 txt-lighter rounded-lg d-flex align-items-center'>Write code <CodeSlash className='ms-1 text-success' /></button>
                </div>
                <div className='pe-2 pt-2'>
                    <button className='btn btn-outline-secondary medium px-3 py-2 txt-lighter rounded-lg d-flex align-items-center'>Give a fun fact <MortarboardFill className='ms-1' style={{ color: 'rgb(118, 208, 235)' }} /></button>
                </div>
                <div className='pe-2 pt-2'>
                    <button className='btn btn-outline-secondary medium px-3 py-2 txt-lighter rounded-lg d-flex align-items-center'>Analyze data <BarChartFill className='ms-1' style={{ color: 'rgb(118, 208, 235)' }} /></button>
                </div>
                <div className='pe-2 pt-2'>
                    <button className='btn btn-outline-secondary medium px-3 py-2 txt-lighter rounded-lg d-flex align-items-center'>Make a plan <LightbulbFill className='ms-1 text-warning' /></button>
                </div>
            </div>
        </div>
    );
}

export default ConversationsWelcomeScreen;

import React from 'react';
import { saveConversation } from '../api/conversation';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Plus } from 'react-bootstrap-icons';
import { useConversations } from '../contexts/ConversationsContext';

function CreateNewChat() {
    const { conversations, addConversation } = useConversations();
    const navigate = useNavigate();

    const handleCreateNewChat = async () => {
        const conversation = {
            subject: "Draft",
        };
        try {
            const newConversation = await saveConversation(conversation);
            addConversation(newConversation);
            navigate('/' + newConversation.uuid);
        } catch (error) {
            toast.error(error.message || "Failed to create conversation");
        }
    };

    return (
        <button onClick={handleCreateNewChat} className="btn btn-basic d-flex align-items-center rounded-lg py-2">
            <Plus className="h5 me-1 mb-0" /> New chat
        </button>
    );
}

export default CreateNewChat;

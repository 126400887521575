import React, { useState } from 'react';
import styles from './Messages.module.css';
import PulseLoader from "react-spinners/PulseLoader";
import ConversationsWelcomeScreen from '../ConversationsWelcomeScreen';
import Message from './Message';


function Messages({ messages, loadingResponse }) {
    return (
        <div className={`${styles.messagesWrapper}`}>

            {messages.length == 0 ? (
                <ConversationsWelcomeScreen />
            ) : (

                <div className='row'>
                    {messages.map((message) => {

                        return (
                            <Message message={message} />
                        );
                    })}

                    {loadingResponse ? (
                        <div className='col-md-12 d-flex flex-column align-items-center mt-3'>
                            <div className={`${styles.message}`}>
                                <PulseLoader
                                    color='var(--color-text)'
                                />
                            </div>
                        </div>
                    ) : ''}

                </div>

            )}

        </div>
    );
}

export default Messages;

import React from 'react';

const DangerZone = () => {
    return (
        <div>
            <p className='bold' style={{ fontSize: 17 }}>Danger Zone</p>

            <div className='py-4'>
                <span>Delete account + more</span>
            </div>

        </div>
    );
}

export default DangerZone;

import Login from './pages/Login/Login';
import Logout from './pages/Logout';

const authRoutes = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
];

export default authRoutes;
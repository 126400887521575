import { apiUrl } from '../../common/api/config';

const register = async (user) => {

    try {
        const response = await fetch(apiUrl+'/authentication/register/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(user)
        });

        const responseData = await response.json();

        if (response.ok) {
            return { success: true, message: "Registration successful" };
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }
};

export { register };
import React from 'react';

const GeneralSettings = () => {
    return (
        <div>
            <p className='bold' style={{ fontSize: 17 }}>General</p>

            <div className='py-4'>
                <span>Language selection + more</span>
            </div>

        </div>
    );
}

export default GeneralSettings;

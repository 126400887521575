import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Chat, Gear, House, List, ThreeDotsVertical, Trash } from 'react-bootstrap-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import CreateNewChat from './CreateNewChat';
import { useConversations } from '../contexts/ConversationsContext';
import SettingsModal from './Settings/SettingsModal';

function Sidebar() {

    const { conversations, removeConversation } = useConversations();
    const [sidebarActive, setSidebarActive] = useState(true);
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    useEffect(() => {

        function handleResize() {
            if (window.innerWidth < 855) {
                const sidebar = document.getElementById('sidebar');
                if (!sidebar.classList.contains('active')) {
                    sidebar.classList.toggle('active');
                }
                document.documentElement.style.setProperty('--sidebar-width', '0');
            }
        }


        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const toggleSidebar = () => {
        const sidebar = document.getElementById('sidebar');
        sidebar.classList.toggle('active');
        if (sidebar.classList.contains('active')) {
            document.documentElement.style.setProperty('--sidebar-width', '0');
        } else {
            document.documentElement.style.setProperty('--sidebar-width', '236px');
        }
    };

    return (

        <>
            <nav id="sidebar" className={!sidebarActive ? 'active' : ''}>

                <ul className="list-unstyled">

                    <div className='d-flex w-100 align-items-center justify-content-between'>
                        <CreateNewChat />

                        <div className='w-fit pointer hover rounded-lg' id='toggleSidebarButton' onClick={toggleSidebar}>
                            <List className='h5 mb-0' />
                        </div>
                    </div>


                    <div className='sidebar-link-group pt-4'>
                        <li className="nav-item px-2 rounded">
                            <Link to="/" className='nav-link d-flex align-items-center'>
                                <House />
                                <span className='ps-2 medium'>Playground</span>
                            </Link>
                        </li>
                        <li className="nav-item px-2 rounded">
                            <Link onClick={() => { setShowSettingsModal(true) }} className='nav-link d-flex align-items-center'>
                                <Gear />
                                <span className='ps-2 medium'>Settings</span>
                            </Link>
                        </li>
                    </div>

                    <div className='sidebar-link-group'>
                        <div className='pb-2'>
                            <span className='medium bold ps-2'>Conversations</span>
                        </div>

                        {conversations.map((conversation) => (
                            <li className="nav-item px-2 rounded d-flex align-items-center justify-content-between" key={conversation.id}>
                                <Link to={'/' + conversation.uuid} className='nav-link d-flex align-items-center'>
                                    {/* <Chat /> */}
                                    <span className='medium'>{conversation.subject}</span>
                                </Link>
                                <Dropdown align="end">
                                    <Dropdown.Toggle as="div" className="text-muted rounded-circle hover-lg px-1 pointer">
                                        <ThreeDotsVertical />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='border-0 shadow-sm' align='end'>
                                        <Dropdown.Item onClick={(event) => {
                                            event.stopPropagation();
                                            removeConversation(conversation.uuid);
                                        }}>
                                            <Trash className='me-2' /> Delete
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        ))}
                    </div>
                </ul>
            </nav>

            <SettingsModal
                showSettingsModal={showSettingsModal}
                setShowSettingsModal={setShowSettingsModal}
            />
        </>
    );
}

export default Sidebar;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { getConversations, deleteConversation as deleteConversation } from '../api/conversation';

const ConversationsContext = createContext();

export const useConversations = () => {
    return useContext(ConversationsContext);
};

export const ConversationsProvider = ({ children }) => {
    const [conversations, setConversations] = useState([]);

    const fetchConversations = async () => {
        try {
            const fetchedConversations = await getConversations();
            setConversations(fetchedConversations);
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    useEffect(() => {
        fetchConversations();
    }, []);

    const addConversation = (conversation) => {
        setConversations((prevConversations) => [conversation, ...prevConversations]);
    };

    const removeConversation = async (conversationUUID) => {
        try {
            setConversations((prevConversations) =>
                prevConversations.filter((conv) => conv.uuid !== conversationUUID)
            );
            await deleteConversation(conversationUUID);
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const refreshConversations = () => {
        fetchConversations();
    };

    return (
        <ConversationsContext.Provider value={{ conversations, addConversation, refreshConversations, removeConversation }}>
            {children}
        </ConversationsContext.Provider>
    );
};
